<template>
  <div class="main_style">
    <NavBarHome v-if="navBarStyle === '1'" textColor="#000000" :iconUrl="require('@/assets/img/home/logo-2.png')"/>
    <NavBar v-else />
    <div class="header">
      <div class="header-text">
        <p class="header-title">ChainSQL<span style="font-size: 25px;font-family: 'PingFangSC-Regular';margin-left:36px">(V 0.30.5)</span></p>
        <p class="header-desc">
          ChainSQL是全球首个基于区块链的数据库应用平台，综合了区块链技术与传统数据库的优点，不仅具有区块链的分布式、多中心化、可审计的特性，同时兼备传统数据库的快速查询、数据结构优化的特性。
        </p>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="intro">
      <div class="commom-title">产品介绍</div>
      <div class="intro-content">
        <p>
          ChainSQL是全球首个基于区块链的数据库应用平台，综合了区块链技术与传统数据库的优点，不仅具有区块链的分布式、多中心化、可审计的特性，同时兼备传统数据库的快速查询、数据结构优化的特性。两者的结合使得数据库的恢复变得快速，数据可靠性得到质的飞跃，同时使得基于区块链的应用开发变得简易。<br/><br/>ChainSQL平台把对数据库操作的每一条指令都记录到一条交易中，即一个交易对应一个数据库操作，区块链网络会以交易的形式记录下所有对数据库的操作。
        </p>
      </div>
    </div>
    <!-- 核心功能 -->
    <div class="function">
      <div class="commom-title">核心功能</div>
      <div class="content">
        <el-row :gutter="24" class="grid">
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/ChainSQL/function-1.png"
                />
              </div>
              <div class="title">基本功能</div>
              <div class="text">
                表操作功能、数据库基本功能、事务支持、其他转账相关管理
              </div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/ChainSQL/function-2.png"
                />
              </div>
              <div class="title">隐私与安全</div>
              <div class="text">
                建表与授权、记录级权限控制、字段级加密、插拔式支持国密算法
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/ChainSQL/function-3.png"
                />
              </div>
              <div class="title">特色功能</div>
              <div class="text">
                智能合约、CA验签、区块及交易数可控、信任服务、严格模式、链瘦身、审计、Dump、订阅、先入库后共识、表的同步、跨链
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <!-- 产品架构 -->
    <div class="framework">
      <div class="commom-title">产品架构</div>
      <div class="framework-content">
        <img src="https://image.peerfintech.cn/peerfintech_website_v2/product/ChainSQL/framework.png" />
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="advantage">
      <div class="commom-title">产品优势</div>
      <div class="advantage-content">
        <el-row :gutter="24">
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/ChainSQL/advantage-1.png"
                />
              </div>
              <div class="title">广泛兼容</div>
              <div class="text">
                支持MySQL、SQLite、DB2、Oracle、SQLServer、HBase等常见传统数据库和南大通用、武汉达梦、人大金仓、神通数据等国产数据库，方便对接落地
              </div>
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/ChainSQL/advantage-2.png"
                />
              </div>
              <div class="title">创新共识</div>
              <div class="text">
                自主研发的POP（ Proof of Peers ）共识算法，TPS > 4000
              </div>
            </div></el-col
          >
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/ChainSQL/advantage-3.png"
                />
              </div>
              <div class="title">支持国密</div>
              <div class="text">
                拔插式支持国密算法：SM2/3/4等
              </div>
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/ChainSQL/advantage-4.png"
                />
              </div>
              <div class="title">使用便捷</div>
              <div class="text">
                可自动将SQL语句转换成区块链交易
              </div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <!-- 相关项目 -->
    <div class="project">
      <div class="commom-title">相关项目</div>
      <div class="project-content">
        <el-row :gutter="24">
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/ChainSQL/project-1.png"
                />
              </div>
              <div class="title">ChainSQL功能调用平台</div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/ChainSQL/project-2.png"
                />
              </div>
              <div class="title">WisChain、ChainSQL浏览器</div>
            </div></el-col
          >
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/ChainSQL/project-3.png"
                />
              </div>
              <div class="title">爱晚嘉姬汇</div>
            </div></el-col
          >
        </el-row>
        <el-row :gutter="24">
          <el-col :span="8"
            ><div class="grid-content">
              <div class="img">
                <img
                  src="@/assets/img/product/ChainSQL/project-4.png"
                />
              </div>
              <div class="title">咕噜链</div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar";
import NavBarHome from "@/components/NavBarHome";
import Footer from "@/components/Footer";
import {scrollMixin} from '@/mixins/scroll.js'
export default {
  mixins: [scrollMixin],
  components: {
    NavBar,
    NavBarHome,
    Footer,
  },
  mounted() {
    // 页面回退顶部
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
.main_style {
  background-color: #ffffff;
  .content-width {
    width: 1200px;
    margin: 0px auto;
  }
  .commom-title {
    padding: 70px 0px 50px;
    font-size: 32px;
    font-family: "PingFangSC-Medium";
    color: rgba(0, 0, 0, 0.85);
    line-height: 45px;
    text-align: center;
  }
  .header {
    width: 100%;
    height: 540px;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/product/ChainSQL/bg.png")
      center/cover no-repeat;
    .header-text {
      @extend .content-width;
      .header-title {
        font-size: 36px;
        font-family: "PingFangSC-Medium";
        color: #000000;
        line-height: 50px;
        padding-top: 160px;
      }
      .header-desc {
        width: 510px;
        color: rgba(0,0,0,0.75);
        font-size: 20px;
        line-height: 33px;
        margin-top: 36px;
      }
    }
  }
  .intro {
    padding-bottom: 70px;
    .intro-content {
      @extend .content-width;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 25px;
    }
  }
  .function {
    background: #f4f7fc;
    .content {
      @extend .content-width;
      padding-bottom: 20px;
      .grid {
        padding-bottom: 26px;
        .grid-content {
          height: 352px;
          margin-bottom: 24px;
          background: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 42px 24px 0px;
          .img {
            width: 124px;
            height: 124px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .title {
            padding: 24px 0px;
            font-size: 22px;
            font-family: "PingFangSC-Medium";
            color: rgba(0, 0, 0, 0.85);
            line-height: 30px;
          }
          .text {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.45);
            line-height: 22px;
            text-align: center;
          }
        }
      }
    }
  }
  .framework {
    padding-bottom: 70px;
    .framework-content {
      @extend .content-width;
      img {
        width: 100%;
      }
    }
  }
  .advantage {
    background: #f4f7fc;
    .advantage-content {
      @extend .content-width;
      padding-bottom: 70px;
      .el-row {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .grid-content {
        height: 370px;
        background: #ffffff;
        box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.1);
        padding: 40px 48px 0px 40px;
        .img {
          width: 124px;
          height: 124px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          padding: 24px 0px;
          font-size: 22px;
          font-family: "PingFangSC-Medium";
          color: rgba(0, 0, 0, 0.85);
          line-height: 30px;
        }
        .text {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.45);
          line-height: 22px;
        }
      }
    }
  }
  .project {
    .project-content {
      @extend .content-width;
      padding-bottom: 70px;
      .grid-content {
        height: 194px;
        background: #f4f7fc;
        display: flex;
        flex-direction: column;
        align-items: center;
				padding:24px 48px 0px;
        .img {
          width: 66px;
          height: 66px;
          margin-bottom: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          font-size: 22px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 30px;
					text-align: center;
        }
      }
      .el-row {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    
  }
}
</style>
